@tailwind base;
@tailwind components;
@tailwind utilities;

/* Josefin Sans */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
/* Inter */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* OVERRIDE STYLES */

/* TAILWIND */
.create-fuze-button {
  @apply rounded-md pl-4 pr-4 pt-2 pb-2 bg-fuzerPurp text-white text-xl font-bold transition-colors select-none hover:bg-fuzerPurpDark; 
}
.fuze-action-button {
  @apply rounded-md pl-4 pr-4 pt-2 pb-2 w-[200px] text-center bg-fuzerPurp text-white text-xl font-bold transition-colors select-none hover:bg-white hover:text-fuzerPurpDark; 
}

.change-trigger-button {
  @apply rounded-md pl-4 pr-4 pt-2 pb-2 border-2 border-fuzerPurp text-fuzerPurp text-xl font-bold transition-colors select-none hover:bg-white hover:text-fuzerPurpDark dark:text-white dark:bg-fuzerPurp hover:dark:text-white hover:dark:bg-fuzerPurpDark; 
}
.change-trigger-account-button {
  @apply rounded-md pl-4 pr-4 pt-[5px] pb-[5px] border-2 border-fuzerPurp text-fuzerPurp text-sm font-bold transition-colors select-none hover:bg-white hover:text-fuzerPurpDark dark:text-white dark:bg-fuzerPurp hover:dark:text-white hover:dark:bg-fuzerPurpDark; 
}

.list-bullet {
  @apply rounded-full h-[20px] w-[20px] border-fuzerPurp border-[5px] mr-[15px];
}

.bullet-item {
  @apply text-[18px] font-semibold text-gray-800 dark:text-white;
}

.link-text {
  @apply text-fuzerPurpDark underline w-fit cursor-pointer hover:text-fuzerPurp dark:text-fuzerPurp;
}

.fuzer-button {
  @apply rounded-full pl-[15px] pr-[15px] pt-[5px] pb-[5px] font-bold hover:bg-opacity-80
}

.discard-button {
  @apply bg-red-500 text-white
}

.fuze-component:last-child{
  @apply border-b-2;
}

.fuze-action-custom-field-input {
  @apply w-[250px] border-fuzerPurp border-2 bg-transparent p-2 text-lg text-black dark:text-white
}

/* VANILLA CSS */

@keyframes loading {
  0%, 100% {
    background-color: rgba(209, 213, 219, 0.5); /* bg-gray-300 with 50% opacity */
  }
  50% {
    background-color: rgba(229, 231, 235, 0.5); /* bg-gray-400 with 50% opacity */
  }
}

.skeleton-loading {
  @apply bg-gray-300 animate-loading;
}

.animate-loading {
  animation: loading 1.5s ease-in-out infinite;
}

.animate-new-action-button {
  animation: new-action-button 2s forwards;
}

@keyframes slide-from-top {
  0% {
    transform: translateY(-100%)
  }
  100% {
    transform: translateY(0)
  }
}

@keyframes slide-from-left {
  0% {
    transform: translateX(-100%) scaleX(0.5) scaleY(0.2);
  }
  50% {
    transform: translateX(-50%) scaleX(0.8) scaleY(0.5);
  }
  100% {
    transform: translateX(0) scaleX(1) scaleY(1);
  }
}

@keyframes slide-from-right {
  0% {
    transform: translateX(10%);
  }
  50% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(0);
  }
}


@keyframes scale-up {
  0% {
    transform: scale(0)
  }
  100% {
    transform: scale(1)
  }
}

@keyframes new-action-button {
  0% {
    visibility: visible;
    transform: scale(0)
  }
  100% {
    visibility: visible;
    transform: scale(1)
  }
}

@keyframes fade {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

@keyframes giggle {
  0%, 100% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}